import React, { useState, useEffect, useRef, useMemo } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  useQuery,
} from '@apollo/client';
import * as THREE from 'three';
import SpriteText from 'three-spritetext';
import tokenData from './tokenData.json';

// Token IDs (Ecosystem tokens)
const tokenIds = [
  "0x0990dead50d6a9e13a7cf44d00a6020ea725e7c8",
  "0x1ce10867a7d779cc4e1b82a5a6a9d2e3768ad0c3",
  "0x26d979a0c0830e02eb3b4ca6b1d1d0b6263c188f",
  "0x282cecf3fef68cf38ee3e3880f69572c376b9cc1",
  "0x286063c4ad66adf0643d5567dc31cc1bb514bbb7",
  "0x299b107b8945cf3ac8ecd1f69dbb9204bf27fa20",
  "0x2cda6c4d9c0f27d2565d688d292d3b0568dd613b",
  "0x365f3dab5b2249576a81a84e159bdd8d2b9ad8eb",
  "0x47273ee3360b936e1c9f2bc808da76e1df4b43c4",
  "0x4b0cc8809d9ec2ea72f3b1bccb8d0b7597320683",
  "0x4e6a5bf1a97912269d5af5da4861088507e16fa8",
  "0x5b17a3d673c47c30a1e87e238dd31ad437158141",
  "0x61496615a3cc5f3bed6dad8cf3945cb6d7765f02",
  "0x6198d4e91e66508b887980a0665d49facd3cb89c",
  "0x697fc467720b2a8e1b2f7f665d0e3f28793e65e8",
  "0x6e22dcfdf550f35b68276216bd46f51d99b77263",
  "0x6d2dc71afa00484c48bff8160dbddb7973c37a5e",
  "0x6f8cbfa5072462282f331967730edce532a73b69",
  "0x708130c48511942b1488118bbdf5ca68f0b5c6fe",
  "0x737fd5b88524d0b90b984ba4f64a6b81b1a1dbc0",
  "0x75da7f3e6811e56b6f8e0a21ea79cffaef0fc2b8",
  "0x868c653acd878dafa2a4aa70421f40c0fec614a8",
  "0x89e30dfc4a68355c1e9cd173c1632835fe4807e3",
  "0x99d6c086bf13f8dc7858629ca637fb900bb58925",
  "0xa3e20671eb098321305c925d16e0d38b443ebd55",
  "0xa9208eed6251e5ccd4d495d8c1c63f78588453d7",
  "0xaba034923f29bd8e7c09afe7f2e5b255b18bfd48",
  "0xad704adf05edb9187cdcde5d29556aadaf92e2aa",
  "0xafcaef62344c5242c20158eb7530ba4ce78de18c",
  "0xb6bad00525221eb28eb911a0b1162a0709b4ce57",
  "0xbe85caacdf811649c7d30598b2bc5674549c5bd5",
  "0xcd80146285ce56def3cf647e79cc3690df139cea",
  "0xd49688bdd162feac18026cea6c7546b4ead67089",
  "0xd5e6e4e24cb3cee9277c7a957d39bfe83c8ffaa2",
  "0xf49c5dd8f51f36f609e4c87e20bcc7aadc51aefb",
  "0xf4a0c336256155726d5aad620a241bd0edb2d681",
  "0xf924da4b2ddf0e8474c65ee046fbd07abc6c8e48",
  "0xfa9ff85e0df45752df23d65d3b91e2b788c197bf",
  "0xa1aed13d95c1eaf0c69a77bb339b07f382fcd7dd",
  "0xc3ea115f6af00e5f5e64044cc0f19b1a97335da8",
  "0xd5968f289ffb6eafec7c9108846e4b03a166ae80",
  "0xf36a961c05bcc239e2ebcdce94c975b4e354d94a",
  "0xf3f7301adee9d32486e86b6992d04e0e8ba7d139",
  "0x7164033ec242d3d868fe3b2f7b00a85c229b28c8",
  "0x9247376301e55e887319bca1cb096a8249b32270"
];

// Blacklist (addresses to always exclude)
const blackList = [
  // Add blacklisted addresses here
  "0xbbea78397d4d4590882efcc4820f03074ab2ab29",
  "0x382973e90c96b0723d13dab79286be6032a204b3"
];

// Whitelist
const whiteList = [
  // Add whitelisted addresses here
];

// Greenlist
const greenList = [
  // Add greenlisted addresses here
];

// Goldlist
const goldList = [
  // Add goldlisted addresses here
];

const PAGE_SIZE = 1000; // Adjust as needed

const QUERY = gql`
  query GetPairs($where: Pair_filter, $first: Int, $skip: Int) {
    pairs(
      orderBy: reserveUSD
      where: $where
      orderDirection: desc
      first: $first
      skip: $skip
      subgraphError: allow
    ) {
      id
      reserveUSD
      token0 {
        id
        name
      }
      token1 {
        id
        name
      }
    }
  }
`;

function buildGraph(pairsData) {
  const nodesMap = {};
  const links = [];

  pairsData.forEach((pair) => {
    const token0 = pair.token0;
    const token1 = pair.token1;

    if (!nodesMap[token0.id]) {
      nodesMap[token0.id] = { id: token0.id, name: token0.name };
    }
    if (!nodesMap[token1.id]) {
      nodesMap[token1.id] = { id: token1.id, name: token1.name };
    }

    links.push({
      source: token0.id,
      target: token1.id,
      value: parseFloat(pair.reserveUSD),
      id: pair.id,
      token0: pair.token0,
      token1: pair.token1,
      reserveUSD: parseFloat(pair.reserveUSD),
    });
  });

  return { nodes: Object.values(nodesMap), links };
}

function Graph() {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [linkHover, setLinkHover] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const fgRef = useRef();
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // State variables for toggles
  const [showNonEcosystemNodes, setShowNonEcosystemNodes] = useState(false);
  const [showWhiteList, setShowWhiteList] = useState(true);
  const [showGreenList, setShowGreenList] = useState(true);
  const [showGoldList, setShowGoldList] = useState(true);

  const where = {
    or: [{ token0_in: tokenIds }, { token1_in: tokenIds }],
  };

  const { loading, error, data, fetchMore } = useQuery(QUERY, {
    variables: { where, first: PAGE_SIZE, skip: 0 },
    notifyOnNetworkStatusChange: true,
  });

  // Load initial data and handle pagination
  useEffect(() => {
    if (data && data.pairs.length > 0) {
      const newGraph = buildGraph(data.pairs);

      setGraphData((prevGraphData) => {
        const combinedNodesMap = {
          ...prevGraphData.nodes.reduce(
            (acc, node) => ({ ...acc, [node.id]: node }),
            {}
          ),
        };
        newGraph.nodes.forEach((node) => {
          combinedNodesMap[node.id] = node;
        });

        const combinedLinksMap = {
          ...prevGraphData.links.reduce(
            (acc, link) => ({ ...acc, [link.id]: link }),
            {}
          ),
        };
        newGraph.links.forEach((link) => {
          combinedLinksMap[link.id] = link;
        });

        return {
          nodes: Object.values(combinedNodesMap),
          links: Object.values(combinedLinksMap),
        };
      });

      // Prepare for next fetch
      if (data.pairs.length < PAGE_SIZE) {
        setHasMore(false);
      } else {
        setSkip((prevSkip) => prevSkip + PAGE_SIZE);
      }
    } else {
      setHasMore(false);
    }
  }, [data]);

  // Fetch more data when available
  useEffect(() => {
    if (hasMore && !loading) {
      fetchMore({
        variables: { skip },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.pairs.length === 0) {
            setHasMore(false);
            return prevResult;
          }

          return {
            pairs: [...prevResult.pairs, ...fetchMoreResult.pairs],
          };
        },
      });
    }
  }, [hasMore, loading, skip]);

  // Adjust force strength based on reserveUSD
  useEffect(() => {
    if (fgRef.current) {
      fgRef.current.cameraPosition(
        { x: 0, y: 0, z: 5000 }, // Adjust z to zoom out
        { x: 0, y: 0, z: 0 },    // Look at the center
        0                        // No animation
      );
    

      // Set link strength dynamically based on reserveUSD
      fgRef.current
        .d3Force('link')
        .strength((link) => Math.log(link.reserveUSD + 1) / 10)
        .distance((link) => Math.log(link.reserveUSD + 1) * 300);

      // Increase node repulsion for more spread
      fgRef.current.d3Force('charge').strength(-750);

      // Reheat simulation
      fgRef.current.d3ReheatSimulation();
    }
  }, [graphData]);
  

  const filteredGraphData = useMemo(() => {
    // Convert lists to lowercase for case-insensitive comparison
    const blackListSet = new Set(blackList.map((id) => id.toLowerCase()));
    const whiteListSet = new Set(whiteList.map((id) => id.toLowerCase()));
    const greenListSet = new Set(greenList.map((id) => id.toLowerCase()));
    const goldListSet = new Set(goldList.map((id) => id.toLowerCase()));
    const tokenIdsSet = new Set(tokenIds.map((id) => id.toLowerCase()));

    const filteredNodes = graphData.nodes.filter((node) => {
      const nodeId = node.id.toLowerCase();

      // Exclude blacklisted nodes
      if (blackListSet.has(nodeId)) {
        return false;
      }

      // Always include ecosystem nodes
      if (tokenIdsSet.has(nodeId)) {
        return true;
      }

      // Include based on lists and toggles
      if (whiteListSet.has(nodeId) && showWhiteList) {
        return true;
      }
      if (greenListSet.has(nodeId) && showGreenList) {
        return true;
      }
      if (goldListSet.has(nodeId) && showGoldList) {
        return true;
      }

      // Include non-ecosystem nodes if toggle is on
      if (showNonEcosystemNodes) {
        return true;
      }

      // Exclude by default
      return false;
    });

    // Create a set of node IDs for easy lookup
    const nodeIdsSet = new Set(filteredNodes.map((node) => node.id.toLowerCase()));

    // Filter links where both source and target nodes are included
    const filteredLinks = graphData.links.filter((link) => {
      const sourceId =
        typeof link.source === 'object'
          ? link.source.id.toLowerCase()
          : link.source.toLowerCase();
      const targetId =
        typeof link.target === 'object'
          ? link.target.id.toLowerCase()
          : link.target.toLowerCase();
      return nodeIdsSet.has(sourceId) && nodeIdsSet.has(targetId);
    });

    return {
      nodes: filteredNodes,
      links: filteredLinks,
    };
  }, [
    graphData,
    showNonEcosystemNodes,
    showWhiteList,
    showGreenList,
    showGoldList,
  ]);

  if (loading && graphData.nodes.length === 0) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  // Function to create a neon glow material
  function createNeonMaterial(color) {
    return new THREE.MeshLambertMaterial({
      color: color,
      emissive: color,
      emissiveIntensity: 1.5,
      transparent: false,
      opacity: 0.9,
    });
  }

  // Handle node click
  const handleNodeClick = (node) => {
    setSelectedNode(selectedNode && selectedNode.id === node.id ? null : node);
  };

  // Adjust link width and color

  const getLinkWidth = (link) => {
    if (
      selectedNode &&
      (link.source.id === selectedNode.id || link.target.id === selectedNode.id)
    ) {
      return Math.log(link.reserveUSD + 1) * 2.5;
    }
    return 3.7;
  };
  // Handle link hover
  const handleLinkHover = (link) => {
    if (
      selectedNode &&
      link &&
      (link.source.id === selectedNode.id || link.target.id === selectedNode.id)
    ) {
      setLinkHover(link);
    } else {
      setLinkHover(null);
    }
  };

  // Handle link click
  const handleLinkClick = (link, event) => {
    if (
      selectedNode &&
      link &&
      (link.source.id === selectedNode.id || link.target.id === selectedNode.id)
    ) {
      // Open submenu or perform desired action
      openSubmenu(link, event.clientX, event.clientY);
    }
  };

  // Handle background click to clear selection
  const handleBackgroundClick = () => {
    setSelectedNode(null);
    setLinkHover(null);
  };


  const getLinkColor = (link) => {
    if (
      selectedNode &&
      (link.source.id === selectedNode.id || link.target.id === selectedNode.id)
    ) {
      return 'cyan';
    }
    return 'magenta'; // Use magenta instead of green
  };

  // Node object with neon material
  const nodeThreeObject = (node) => {
    const radius = 60;
    const nodeId = node.id.toLowerCase();

    let nodeColor;
    if (selectedNode && nodeId === selectedNode.id.toLowerCase()) {
      nodeColor = 'cyan'; // Highlighted node
    } else if (tokenIds.includes(nodeId)) {
      nodeColor = 'purple'; // Ecosystem nodes
    } else if (whiteList.includes(nodeId)) {
      nodeColor = 'yellow';
    } else if (goldList.includes(nodeId)) {
      nodeColor = 'orange';
    } else {
      nodeColor = 'grey'; // Default color for other nodes
    }

    // Create neon material
    const material = createNeonMaterial(nodeColor);

    const geometry = new THREE.SphereGeometry(radius, 32, 32);
    const sphere = new THREE.Mesh(geometry, material);

    // Add neon text label
    const sprite = new SpriteText(node.name, radius * 0.5, 'white');
    sprite.color = 'white';
    sprite.textHeight = radius * 1.5;
    sprite.material.depthTest = false;
    sprite.renderOrder = 1;
    sprite.position.set(0, radius + 5, 0);

    sphere.add(sprite);
    return sphere;
  };

  return (
    <div>
      <ForceGraph3D
        ref={fgRef}
        graphData={filteredGraphData}
        backgroundColor="#000000"
        linkWidth={getLinkWidth}
        linkColor={getLinkColor}
        onLinkHover={handleLinkHover}
        onLinkClick={handleLinkClick}
        onNodeClick={handleNodeClick}
        nodeThreeObject={nodeThreeObject}
        enableNodeDrag={false}
        enableLinkDrag={false}
        enableNavigationControls={true}
        rendererConfig={{
          antialias: true,
          alpha: true,
        }}
      />
      {selectedNode && <TokenStatsSubmenu node={selectedNode} />}
      <FilterToggles
        showNonEcosystemNodes={showNonEcosystemNodes}
        setShowNonEcosystemNodes={setShowNonEcosystemNodes}
        showWhiteList={showWhiteList}
        setShowWhiteList={setShowWhiteList}
        showGreenList={showGreenList}
        setShowGreenList={setShowGreenList}
        showGoldList={showGoldList}
        setShowGoldList={setShowGoldList}
      />
    </div>
  );
}

// TokenStatsSubmenu component
function TokenStatsSubmenu({ node }) {
  if (!node) return null;

  const submenuStyle = {
    position: 'absolute',
    bottom: '3px',
    right: '3px',
    width: '300px',
    background: 'black',
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '3px',
    zIndex: 5000,
    fontFamily: '"Undertale", sans-serif',
    color: 'white',
  };

  const titleStyle = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '2px',
  };

  const addressContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2px',
  };

  const imageStyle = {
    width: '100px',
    height: '100px',
  };

  const addressStyle = {
    color: 'white',
    marginLeft: '5px',
    flexGrow: 1,
    wordBreak: 'break-all',
  };

  const websiteStyle = {
    marginBottom: '10px',
    display: 'flex',
  };

  const websiteLinkStyle = {
    fontWeight: 'bold',
    marginRight: '15px',
    textDecoration: 'none',
    color: 'white',
  };

  const descriptionStyle = {
    color: 'white',
    marginRight: '15px',
  };

  const buttonStyle = {
    width: '18%', // Adjust as needed to fit all buttons in one row
    padding: '3px',
    fontFamily: '"Undertale", sans-serif',
  };
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  const copyButtonStyle = {
    marginTop: '1px',
    fontFamily: '"Undertale", sans-serif',
  };

  const tokenAddress = node.id;
  const tokenName = node.name;
  const tokenSymbol = data.symbol || '👀';
  const tokenWebsite = 'https://t.me/sunnyspubv2'; // Add website if available
  const tokenDescription = 'Suck up all tha liquitties! 💜';

  // URLs
  const pulsexTradeUrl = `https://pulsex.mypinata.cloud/ipfs/bafy.../#/?outputCurrency=${tokenAddress}`;
  const pulsexStatsUrl = `https://pulsex.mypinata.cloud/ipfs/bafy.../#/info/v2/token/${tokenAddress}`;
  const addLiquidityUrl = `https://pulsex.mypinata.cloud/ipfs/bafy.../#/add/v2/pls/${tokenAddress}`;
  const chartUrl = `https://dexscreener.com/pulsechain/${tokenAddress}?embed=1&theme=dark&trades=0`;
  const scannerUrl = `https://scan.mypinata.cloud/ipfs/bafy.../#/address/${tokenAddress}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tokenAddress);
    alert('Token address copied to clipboard! 💜');
  };
  const openIframe = (url, title) => {
    openIframeWindow(url, title);
  };

  return (
    <div style={submenuStyle}>
      <div style={titleStyle}>
      {node.name} {tokenSymbol && `(${tokenSymbol})`}
      </div>
      <div style={addressContainerStyle}>
        <img
          src={`images/${tokenAddress}.webp`}
          alt={tokenName}
          style={imageStyle}
        />
        <div style={addressStyle}>
          <div>{tokenAddress}</div>
          <button onClick={copyToClipboard} style={copyButtonStyle}>
            Copy
          </button>
        </div>
      </div>
      {tokenWebsite && (
        <div style={websiteStyle}>
          <a
            href={tokenWebsite}
            target="_blank"
            rel="noopener noreferrer"
            className="rgb-link"
            style={websiteLinkStyle}
          >
            {tokenWebsite}
          </a>
        </div>
      )}
      <div style={descriptionStyle}>{tokenDescription}</div>
      <div style={buttonContainerStyle}>
        <button
          onClick={() => openIframe(pulsexTradeUrl, 'Trade')}
          style={buttonStyle}
        >
          Trade
        </button>
        <button
          onClick={() => openIframe(addLiquidityUrl, 'Add Liquidity')}
          style={buttonStyle}
        >
          Add Liq
        </button>
        <button
          onClick={() => openIframe(pulsexStatsUrl, 'PulseX Stats')}
          style={buttonStyle}
        >
          Stats
        </button>
        <button
          onClick={() => openIframe(chartUrl, 'Chart')}
          style={buttonStyle}
        >
          Chart
        </button>
        <button
          onClick={() => openIframe(scannerUrl, 'Scanner')}
          style={buttonStyle}
        >
          Scanner
        </button>
      </div>
    </div>
  );
}

function FilterToggles({
  showNonEcosystemNodes,
  setShowNonEcosystemNodes,
  showWhiteList,
  setShowWhiteList,
  showGreenList,
  setShowGreenList,
  showGoldList,
  setShowGoldList,
}) {
  const toggleContainerStyle = {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    background: 'rgba(0, 0, 0, 0.8)',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    zIndex: 1000,
  };

  return (
    <div style={toggleContainerStyle}>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showNonEcosystemNodes}
            onChange={(e) => setShowNonEcosystemNodes(e.target.checked)}
          />
          Show Non-Ecosystem Tokens
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showWhiteList}
            onChange={(e) => setShowWhiteList(e.target.checked)}
          />
          Toggle PRC20s
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showGreenList}
            onChange={(e) => setShowGreenList(e.target.checked)}
          />
          Display ??? PROFIT
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showGoldList}
            onChange={(e) => setShowGoldList(e.target.checked)}
          />
          Do not touch under any circumstances
        </label>
      </div>
    </div>
  );
}


// TokenStatsSubmenu component 
function TokenStatsSubmenu({ node }) {
  if (!node) return null;

  const submenuStyle = {
    position: 'absolute',
    bottom: '3px',
    right: '3px',
    width: '300px',
    background: 'black',
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '3px',
    zIndex: 5000,
    fontFamily: '"Undertale", sans-serif', // Do you wanna have a bad time? >:3
  };
  
  
  const titleStyle = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '2px',
  };
  
  const addressContainerStyle = {
    
    display: 'flex',
    alignItems: 'center',
    marginTop: '2px',
  };
  
  const imageStyle = {
    width: '100px',
    height: '100px',
  };
  
  const addressStyle = {
    color: 'white',
    marginLeft: '5px',
    flexGrow: 1,
    wordBreak: 'break-all',
  };
  
  const websiteStyle = {
    marginBottom: '10px',
    display: 'flex',

  };
  
  const websiteLinkStyle = {
    fontWeight: 'bold',
    marginRight: '15px',
    textDecoration: 'none',
  };

  const descriptionStyle = {
    color: 'white',
    marginRight: '15px',
  };

  const buttonStyle = {
    width: '18%', // Adjust as needed to fit all buttons in one row
    padding: '3px',
    fontFamily: '"Undertale", sans-serif',
  };
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };
  
  const copyButtonStyle = {
    marginTop: '1px',
    fontFamily: '"Undertale", sans-serif',
  };
  


  const tokenAddress = node.id.toLowerCase();

  // Get the token data from the JSON file
  const data = tokenData[tokenAddress] || {};
  const tokenWebsite = data.website || 'https://t.me/sunnyspubv2';
  const tokenImage = data.image || `images/${tokenAddress}.webp`;
  const tokenDescription = data.description || 'Token description here! 💜';
  const tokenName = node.name;
  const tokenSymbol = ''; // Add symbol if available


  // URLs
  const pulsexTradeUrl = `https://pulsex.mypinata.cloud/ipfs/bafybeift2yakeymqmjmonkzlx2zyc4tty7clkwvg37suffn5bncjx4e6xq/#/?outputCurrency=${tokenAddress}`;
  const pulsexStatsUrl = `https://pulsex.mypinata.cloud/ipfs/bafybeift2yakeymqmjmonkzlx2zyc4tty7clkwvg37suffn5bncjx4e6xq/#/info/v2/token/${tokenAddress}`;
  const addLiquidityUrl = `https://pulsex.mypinata.cloud/ipfs/bafybeift2yakeymqmjmonkzlx2zyc4tty7clkwvg37suffn5bncjx4e6xq/#/add/v2/pls/${tokenAddress}`;
  const chartUrl = `https://dexscreener.com/pulsechain/${tokenAddress}?embed=1&theme=dark&trades=0`;
  const scannerUrl = `https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/address/${tokenAddress}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tokenAddress);
    alert('Token address copied to clipboard! 💜');
  };

  return (
    <div style={submenuStyle}>
      <div style={titleStyle}>
        {tokenName} {tokenSymbol}
      </div>
      <div style={addressContainerStyle}>
        <img
          src={`images/${tokenAddress}.webp`}
          alt={tokenName}
          style={imageStyle}
        />
        <div style={addressStyle}>
          <div>{tokenAddress}</div>
          <button onClick={copyToClipboard}   style={copyButtonStyle}>
            Copy
          </button>
        </div>
      </div>
      {tokenWebsite && (
        <div style={websiteStyle}>
          <a
            href={tokenWebsite}
            target="_blank"
            rel="noopener noreferrer"
            className="rgb-link"
            style={websiteLinkStyle}
          >
            {tokenWebsite}
          </a>
        </div>
      )}
      <div style={descriptionStyle}>{tokenDescription}</div>
      <div style={buttonContainerStyle}>
        <button onClick={() => openIframeWindow(pulsexTradeUrl, `⮑ APEING LIFE SAVINGS INTO ${tokenName}! tru degen xD lmao`)}  style={buttonStyle}>
          Trade
        </button>
        <button onClick={() => openIframeWindow(addLiquidityUrl, `⮑ Add liquititties 2 ${tokenName}`)}  style={buttonStyle}>
          Add Liq
        </button>
        <button onClick={() => openIframeWindow(pulsexStatsUrl, `⮑ PulseX Statz 4 ${tokenName}`)}  style={buttonStyle}>
          Stats
        </button>
        <button onClick={() => openIframeWindow(chartUrl, `⮑ Tha UP ONLY chart of ${tokenName}`)}  style={buttonStyle}>Chart</button>
        <button onClick={() => openIframeWindow(scannerUrl, `⮑ ${tokenName}?!?! THATS A SCAN!!!!!`)}  style={buttonStyle}>
          Scanner
        </button>
      </div>
    </div>
  );
}


// Implement openSubmenu and openIframeWindow functions
function openSubmenu(link, x, y) {
  const existingMenu = document.getElementById('submenu');
  if (existingMenu) {
    existingMenu.parentNode.removeChild(existingMenu);
  }

  const menu = document.createElement('div');
  menu.id = 'submenu';
  menu.className = 'submenu';
  menu.style.position = 'absolute';
  menu.style.left = `${x}px`;
  menu.style.top = `${y}px`;
  menu.style.background = 'white';
  menu.style.padding = '10px';
  menu.style.border = '1px solid #ccc';
  menu.style.borderRadius = '5px';
  menu.style.zIndex = '1000';

  const title = document.createElement('div');
  title.textContent = `⮑${link.token0.name} / ${link.token1.name} ⅊⅌`;
  title.style.fontWeight = 'bold';
  title.style.marginBottom = '8px';
  title.style.color = 'black'; 
  menu.appendChild(title);

  const options = [
    {
      name: 'Pulsex Pool Info',
      url: `https://pulsex.mypinata.cloud/ipfs/bafybeift2yakeymqmjmonkzlx2zyc4tty7clkwvg37suffn5bncjx4e6xq/#/info/v2/pool/${link.id}`,
    },
    {
      name: 'Pulsex Trading Pair',
      url: `https://pulsex.mypinata.cloud/ipfs/bafybeift2yakeymqmjmonkzlx2zyc4tty7clkwvg37suffn5bncjx4e6xq/#/?inputCurrency=${link.token0.id}&outputCurrency=${link.token1.id}`,
    },
    {
      name: 'Pulsex Add LP',
      url: `https://pulsex.mypinata.cloud/ipfs/bafybeift2yakeymqmjmonkzlx2zyc4tty7clkwvg37suffn5bncjx4e6xq/#/add/v2/${link.token0.id}/${link.token1.id}`,
    },
    {
      name: 'Dexscreener Pair Chart',
      url: `https://dexscreener.com/pulsechain/${link.id}?embed=1&theme=dark&trades=0`,
    },
    {
      name: 'Scanner LP Page',
      url: `https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/address/${link.id}`,
    },
  ];

  options.forEach(opt => {
    const btn = document.createElement('button');
    btn.textContent = opt.name;
    btn.style.display = 'block';
    btn.style.width = '100%';
    btn.style.margin = '5px 0';
    btn.onclick = () => {
      openIframeWindow(opt.url, `${link.token0.name} / ${link.token1.name}`);
      closeSubmenu();
    };
    menu.appendChild(btn);
  });

  document.body.appendChild(menu);

  // Close submenu if clicking outside
  const closeOnOutsideClick = (event) => {
    if (!menu.contains(event.target)) {
      closeSubmenu();
    }
  };

  const closeSubmenu = () => {
    document.body.removeChild(menu);
    document.removeEventListener('mousedown', closeOnOutsideClick);
  };

  document.addEventListener('mousedown', closeOnOutsideClick);
}

function openIframeWindow(url, title) {
  const iframeWrapper = document.createElement('div');
  iframeWrapper.className = 'iframe-wrapper';
  iframeWrapper.style.position = 'absolute';
  iframeWrapper.style.width = '600px';
  iframeWrapper.style.height = '400px';
  iframeWrapper.style.left = '100px';
  iframeWrapper.style.top = '100px';
  iframeWrapper.style.border = '2px solid #ccc';
  iframeWrapper.style.zIndex = '1000';
  iframeWrapper.style.background = 'white';
  iframeWrapper.style.resize = 'both'; // Enables resizing
  iframeWrapper.style.overflow = 'hidden';

  let isMinimized = false;

  const header = document.createElement('div');
  header.style.display = 'flex';
  header.style.justifyContent = 'space-between';
  header.style.alignItems = 'center';
  header.style.padding = '5px';
  header.style.background = '#f0f0f0';
  header.style.borderBottom = '1px solid #ccc';
  header.style.cursor = 'move'; // For dragging the iframe

  const titleEl = document.createElement('span');
  titleEl.textContent = `⮑ ${title}`;
  header.appendChild(titleEl);

  const controls = document.createElement('div');
  const minimizeBtn = document.createElement('button');
  minimizeBtn.textContent = '▁';
  minimizeBtn.onclick = () => {
    if (isMinimized) {
      iframeWrapper.style.height = iframeWrapper.dataset.originalHeight || '400px';
      iframeWrapper.style.width = iframeWrapper.dataset.originalWidth || '600px';
      iframeWrapper.style.overflow = 'hidden';
      isMinimized = false;
    } else {
      iframeWrapper.dataset.originalHeight = iframeWrapper.style.height;
      iframeWrapper.dataset.originalWidth = iframeWrapper.style.width;
      iframeWrapper.style.height = '30px'; // Only the header remains visible
      iframeWrapper.style.overflow = 'hidden';
      isMinimized = true;
    }
  };

  const maximizeBtn = document.createElement('button');
  maximizeBtn.textContent = '⧉';
  maximizeBtn.onclick = () => {
    if (iframeWrapper.style.width === '100%') {
      iframeWrapper.style.width = iframeWrapper.dataset.originalWidth || '600px';
      iframeWrapper.style.height = iframeWrapper.dataset.originalHeight || '400px';
      iframeWrapper.style.left = '100px';
      iframeWrapper.style.top = '100px';
    } else {
      iframeWrapper.dataset.originalWidth = iframeWrapper.style.width;
      iframeWrapper.dataset.originalHeight = iframeWrapper.style.height;
      iframeWrapper.style.width = '100%';
      iframeWrapper.style.height = '100%';
      iframeWrapper.style.left = '0';
      iframeWrapper.style.top = '0';
    }
  };

  const closeBtn = document.createElement('button');
  closeBtn.textContent = '☒';
  closeBtn.onclick = () => {
    document.body.removeChild(iframeWrapper);
  };

  controls.appendChild(minimizeBtn);
  controls.appendChild(maximizeBtn);
  controls.appendChild(closeBtn);
  header.appendChild(controls);

  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.width = '100%';
  iframe.style.height = 'calc(100% - 30px)';
  iframe.style.border = 'none';

  iframeWrapper.appendChild(header);
  iframeWrapper.appendChild(iframe);
  document.body.appendChild(iframeWrapper);

  // Make the iframe draggable
  let isDragging = false;
  let startX, startY, startLeft, startTop;

  header.addEventListener('mousedown', (e) => {
    isDragging = true;
    startX = e.clientX;
    startY = e.clientY;
    startLeft = parseInt(iframeWrapper.style.left);
    startTop = parseInt(iframeWrapper.style.top);
    e.preventDefault();
  });

  document.addEventListener('mousemove', (e) => {
    if (isDragging) {
      const dx = e.clientX - startX;
      const dy = e.clientY - startY;
      iframeWrapper.style.left = `${startLeft + dx}px`;
      iframeWrapper.style.top = `${startTop + dy}px`;
    }
  });

  document.addEventListener('mouseup', () => {
    isDragging = false;
  });
}



function GraphWrapper() {
  const client = new ApolloClient({
    uri: 'https://psycho.sunnys.pub/atropa/graphql-proxy.php',
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Graph />
    </ApolloProvider>
  );
}

export default GraphWrapper;

