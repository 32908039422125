{
  "0x0990dead50d6a9e13a7cf44d00a6020ea725e7c8": {
    "website": "https://mdai.meth.house",
    "image": "https://dd.dexscreener.com/ds-data/tokens/pulsechain/0x0990dead50d6a9e13a7cf44d00a6020ea725e7c8.png?size=lg",
    "description": "Rewards Ethereum Dai - 1% tax."
  },
  "0x1ce10867a7d779cc4e1b82a5a6a9d2e3768ad0c3": {
    "website": "https://centr.rewardr.cash",
    "image": "https://dd.dexscreener.com/ds-data/tokens/pulsechain/0x1ce10867a7d779cc4e1b82a5a6a9d2e3768ad0c3.png?size=lg",
    "description": "Rewards (eDAI/pDAI)/(eWBTC/pWBTC) LP - 1% tax."
  },
  "0x26d979a0c0830e02eb3b4ca6b1d1d0b6263c188f": {
    "website": "https://mfrax.meth.house",
    "image": "mfrax.png",
    "description": "Rewards Ethereum Frax - 1% tax."
  },
  "0x282cecf3fef68cf38ee3e3880f69572c376b9cc1": {
    "website": "https://usdcr.rewardr.cash",
    "image": "usdcr.png",
    "description": "Rewards PulseChain USDC - 1% tax."
  },
  "0x286063c4ad66adf0643d5567dc31cc1bb514bbb7": {
    "website": "https://dysnomia.atropa.community",
    "image": "chata1a.png",
    "description": "Rewards A1A_Eco LAU - 3% tax."
  },
  "0x299b107b8945cf3ac8ecd1f69dbb9204bf27fa20": {
    "website": "https://414r.rewardr.cash",
    "image": "414r.png",
    "description": "Rewards Bff/2cc/Crows/monat/Atropa/teddy/nukes/tsfi LP - 1% tax."
  },
  "0x2cda6c4d9c0f27d2565d688d292d3b0568dd613b": {
    "website": "https://BEAr.rewardr.cash",
    "image": "BEAr.png",
    "description": "Rewards Teddy Bear - 2% tax."
  },
  "0x365f3dab5b2249576a81a84e159bdd8d2b9ad8eb": {
    "website": "https://lusdlp.rewardr.cash",
    "image": "lusdlpx.png",
    "description": "Rewards eLUSD/pLUSD LP - 3% tax."
  },
  "0x47273ee3360b936e1c9f2bc808da76e1df4b43c4": {
    "website": "https://dair.rewardr.cash",
    "image": "dair.png",
    "description": "Rewards PulseChain DAI - 1% tax."
  },
  "0x4e6a5bf1a97912269d5af5da4861088507e16fa8": {
    "website": "https://lendr.rewardr.cash",
    "image": "lendr.png",
    "description": "Rewards PulseChain AAVE - 3% tax."
  },
  "0x5b17a3d673c47c30a1e87e238dd31ad437158141": {
    "website": "https://rwdr.rewardr.cash",
    "image": "rwdr.png",
    "description": "Rewards Pulse - 1% tax."
  }
  // Add more tokens as needed
}
